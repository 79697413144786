import React from 'react'
import { productVariantPropType } from 'lib/prop-types'
import Image from 'components/Image'
import Link from 'components/Link'
import formatMoney from 'lib/format-money'
import createLink from 'lib/create-link'
import parseToUrl from 'lib/parse-to-url'
import imageUrlForProduct from 'lib/image-url-for-product'

const SearchResultProduct = props => {
  const { product } = props
  return (
    <Link
      to={createLink.toProduct(product)}
      className="product__variants--item hover--shadow b-r--3"
    >
      <div className="product__variants--img-box">
        {product.defaultImage ? (
          <div className="product-img--tiny b-r--3">
            <Image
              name={imageUrlForProduct(product)}
              size="small"
              alt={product.defaultImage.alt}
            />
          </div>
        ) : null}
        {product.manufacturer ? (
          <div className="manufacturer-logo">
            <Image
              name={`manufacturer/${parseToUrl(product.manufacturer)}`}
              size="small"
            />
          </div>
        ) : null}
      </div>
      {product.price === 0 ? null : (
        <div className="product__variants--price">
          <div className="text--smallest fw--regular">exkl. MwSt.</div>
          {formatMoney(product.price)}
        </div>
      )}
      <div className="product__variants--title">{product.title}</div>
    </Link>
  )
}

export default SearchResultProduct

SearchResultProduct.propTypes = {
  product: productVariantPropType,
}
