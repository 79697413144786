const invokeNetlifyLambda = require('lib/invoke-netlify-lambda')

const reservedChars = /[.?+*|{}[\]()"\\-]/g

module.exports = async query => {
  return new Promise((resolve, reject) => {
    if (query) {
      if (query.length === 0) {
        resolve([])
      }

      //put all reserved regex characters into double quotes for literal interpretation
      const escapedQuery = query.replace(reservedChars, '"$&"')
      let url = 'search?q=' + encodeURIComponent(escapedQuery) + '*'

      invokeNetlifyLambda.get(url).then(resolve).catch(reject)
    }
  })
}
