const formatNumber = number => {
  if (typeof number !== 'number' && typeof number !== 'string') return ''

  const numberString = number.toString()

  if (numberString.includes('.')) {
    return numberString.replace(/\d(?=(\d{3})+\.)/g, "$&'")
  }
  return numberString.replace(/\d(?=(\d{3})+$)/g, "$&'")
}

export default formatNumber

export const roundToTen = number => Math.round((number ?? 0) / 10) * 10
