import React, { Fragment } from 'react'
import { urlLocationPropType } from 'lib/prop-types'
import { navigate } from 'gatsby'
import Layout from 'components/Layout'
import SearchResultProduct from 'components/Search/SearchResultProduct'
import SearchResultKnowledge from 'components/Search/SearchResultKnowledge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faInfo } from '@fortawesome/free-solid-svg-icons'
import submitSearch from 'lib/search'
import throttle from 'lodash.throttle'
import { Helmet } from 'react-helmet'

class Search extends React.Component {
  constructor(props) {
    super(props)

    let query = ''
    const searchParts = props.location.search.split('=')
    if (typeof searchParts[1] !== 'undefined') {
      query = decodeURI(searchParts[1])
    }

    this.state = {
      query: query,
      result: [],
      isSearching: false,
      error: false,
    }
    this.throttledSearch = throttle(value => this.search(value), 150)
  }

  componentDidMount() {
    if (
      typeof window !== undefined &&
      this.state.query &&
      this.state.query.length > 0
    ) {
      this.search(this.state.query)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props != prevProps &&
      this.state.query !== this.props.location.search.split('=')[1]
    ) {
      this.setState({ query: this.props.location.search.split('=')[1] }, () => {
        this.search(this.state.query)
      })
    }
  }

  search(query) {
    this.setState({ isSearching: true })

    submitSearch(query)
      .then(result => this.setState({ result: result, isSearching: false }))
      .catch(err => {
        console.error(err)
        this.setState({ error: true, isSearching: false })
      })
  }

  handleChange(value) {
    this.setState({
      query: value,
      hideSearchPreview: false,
      error: false,
    })

    if (value.length === 0) {
      this.setState({ searchResult: [] })
      return
    }
    this.throttledSearch(value)
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    this.setState({ error: false, isSearching: false })
    navigate(`/search?q=${this.state.query}`)
  }

  render() {
    const { result } = this.state
    const productResults = result.filter(item => item.type === 'productVariant')
    const knowledgeResults = result.filter(
      item => item.type === 'knowledgeArticle'
    )

    return (
      <Layout>
        <Helmet title="Suchfunktion" />
        <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
        <div className="section bg__flex--grey">
          <div className="section__inner section--padded-small search--mobile">
            <div className="wrap__box--pad-s bt__3--dark">
              <form
                className="search__bar"
                onSubmit={e => this.handleSubmit(e)}
              >
                <input
                  id="search"
                  type="search"
                  placeholder="Suchbegriff eingeben"
                  value={this.state.query}
                  onChange={e => this.handleChange(e.target.value)}
                  autoFocus
                />
                {this.state.query && this.state.query.length > 0 ? (
                  <button className="btn btn--small input--btn">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() =>
                        this.setState(
                          {
                            query: '',
                          },
                          () => this.handleSubmit()
                        )
                      }
                    />
                  </button>
                ) : null}
                <button className="btn btn--small input--btn">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>
            </div>
          </div>
          <div className="section__inner section--padded-small">
            <div className="wrap__box--pad-s bt__3--dark">
              {this.state.query ? (
                this.state.error ? (
                  <div>
                    <p>Bei Ihrer Suche ist ein Fehler aufgetreten.</p>
                    <p>
                      Bitte überprüfen Sie die Schreibweise oder versuchen einen
                      anderen Suchbegriff. Sie können uns gern auch direkt
                      kontaktieren:
                    </p>
                    <a href="mailto:info@heizungsmacher.ch">
                      info@heizungsmacher.ch
                    </a>
                  </div>
                ) : this.state.isSearching ? (
                  <div>Suche…</div>
                ) : (
                  <Fragment>
                    <div className="column--measure-medium callout callout--info">
                      <span className="callout__icon">
                        <FontAwesomeIcon icon={faInfo} />
                      </span>
                      <span className="callout__text">
                        Ihre Suchergebnisse für: &quot;
                        <strong>{this.state.query}</strong>
                        &quot;
                      </span>
                    </div>
                    {this.state.result.length > 0 ? (
                      <div className="column--measure-medium">
                        {productResults.length > 0 ? (
                          <div>
                            <div className="headline--bordered">
                              Gefunden in{' '}
                              <a href="/produkte" className="b">
                                Produkte
                              </a>
                            </div>
                            {productResults.map(item => (
                              <div key={item.id} className="result__item">
                                <SearchResultProduct product={item} />
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {knowledgeResults.length > 0 ? (
                          <div>
                            <div className="headline--bordered">
                              Gefunden in{' '}
                              <a href="/wissen" className="b">
                                Wissen
                              </a>
                            </div>
                            {knowledgeResults.map(item => (
                              <div key={item.id} className="result__item">
                                <SearchResultKnowledge article={item} />
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <p>
                        Zu Ihrer Suche wurden leider keine Ergebnisse gefunden.
                        Bitte überprüfen Sie die Schreibweise oder versuchen
                        einen anderen Suchbegriff.
                      </p>
                    )}
                  </Fragment>
                )
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Search

Search.propTypes = { location: urlLocationPropType }
