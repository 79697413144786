import React from 'react'
import { knowledgeArticlePropType } from 'lib/prop-types'
import Link from 'components/Link'

const SearchResultKnowledge = props => {
  const { article } = props
  return (
    <Link
      to={`/${article.onPage.slug}`}
      className="article__card result__item--link hover--shadow b-r--3"
    >
      <h3 className="article__card--title">{article.title}</h3>
      <div className="article__card--description">{article.description}</div>
    </Link>
  )
}

SearchResultKnowledge.propTypes = {
  article: knowledgeArticlePropType,
}

export default SearchResultKnowledge
